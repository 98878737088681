import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpez4 from "src/components/chromelpez4"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Chromectadisclosure from "src/components/chromectadisclosure"

const chromelpezData = import("./data/data.json");

export default function spachroma() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #fafafa;
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
       }

       #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure p {
         color: #333;
       }

        #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure a {
          color: #007bff;
        }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelpez4 data={chromelpezData}>
        <Chromectadisclosure></Chromectadisclosure>
      </Chromelpez4>

      </section>
    </HomepageLayout>
  )
}

